import TTLCache from '@isaacs/ttlcache';
import { revalidate as solidRevalidate } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import type { PathsWithMethod } from 'openapi-typescript-helpers';
import type { paths } from './__generated__/database';

export const ttlCache = isServer ? null : new TTLCache({ max: 50, ttl: 60_000 * 4 });

export type CacheablePaths = PathsWithMethod<paths, 'get'>;

export async function revalidate(key?: CacheablePaths | Array<CacheablePaths> | void, force?: boolean) {
	if (!ttlCache) {
		await solidRevalidate(key, force);
		return;
	}

	const cacheKeys = Array.from(ttlCache.keys()) as Array<string>;
	if (key) {
		const keys = Array.isArray(key) ? key : [key];
		for (const key of keys) {
			if (key.includes('[')) {
				ttlCache.delete(key);
			} else {
				cacheKeys.filter((k) => k.startsWith(`${key}[`) || k === key).forEach((key) => ttlCache.delete(key));
			}
		}
	} else if (!key) {
		ttlCache.clear();
	}

	await solidRevalidate(key, force);
}
